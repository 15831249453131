import React, { useEffect, useState } from "react";
import { formatDistanceToNow, set } from "date-fns";
import {
  Button,
  MessageBarActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useData } from "@microsoft/teamsfx-react";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { initializeIcons } from "@fluentui/react";

import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import { mapUsersActivityToTableRows } from "../../../tabs/DashBoard/utils/helpers";
import { Alert } from "../../../components/Alert";
import { useTeamsFxContext } from "../../../context/TeamsFxContext";
import { DEFAULT_PAGE_SIZE } from "../../../constants/global";
import { containerBreakpoints } from "../../../utils/breakpoints";
import Pagination, { PaginationConfig } from "../../../components/Pagination";
import { formatDateTime, getTeamsContextChatId } from "../../../utils/helpers";
import { useStoreContext } from "../../../context/StoreContext";
import { useOverviewContext } from "../../../tabs/Overview/context/OverviewContext";
import { ActivityScope } from "./ActivityFilters";
import { useGetActivityData } from "../../hooks/useGetActivityData";

initializeIcons();

export interface ActivityTableRow {
  key: number;
  icon: React.ReactElement;
  message: string;
  date: Date;
}

interface ActivityTableProps {
  activityFilterPeriod: [Date, Date];
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  table: {
    display: "table !important",
  },
  tableColumnIcon: {
    width: "30px",
  },
  tableColumnDate: {
    width: "120px",
    fontSize: "10px",
    textAlign: "right",
    ...containerBreakpoints.xs({
      display: "none",
    }),
  },
});

export const ActivityTable: React.FC<ActivityTableProps> = ({
  activityFilterPeriod,
}) => {
  const styles = useStyles();
  const [rows, setRows] = useState<ActivityTableRow[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationConfig>({
    page: 0,
    limit: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const { teams, teamsUserCredential } = useTeamsFxContext();
  const { userSettings } = useStoreContext();
  const { activityFilterScope } = useOverviewContext();
  const providerChatId = getTeamsContextChatId(teams);

  const { data: userData } = useData(async () => {
    if (teamsUserCredential) {
      return await teamsUserCredential.getUserInfo();
    }
  });

  const { isLoading, isError, isSuccess, data, refetch } = useGetActivityData({
    providerChatId,
    providerUserId:
      activityFilterScope === ActivityScope.Personal || providerChatId === null
        ? userData?.objectId
        : undefined,
    activityFilterPeriod,
    pagination: {
      page: selectedPage + 1,
      limit: DEFAULT_PAGE_SIZE,
    },
  });

  useEffect(() => {
    if (data) {
      setRows(mapUsersActivityToTableRows(data.data));
      setPagination(data.pagination);
    }
  }, [data]);

  useEffect(() => {
    onPageChange(0);
  }, [activityFilterPeriod, activityFilterScope]);

  const onPageChange = (index: number) => {
    setSelectedPage(index);
  };

  return (
    <div data-testid="dashboard-activity-table">
      {isLoading && <TableSkeleton />}

      {isError && (
        <Alert
          intent="error"
          actions={
            <MessageBarActions
              containerAction={
                <Button
                  appearance="transparent"
                  icon={<ArrowCounterclockwise24Regular />}
                  onClick={() => refetch()}
                  data-testid="retry-fetch-activity-button"
                />
              }
            />
          }
        />
      )}

      {isSuccess && rows.length === 0 && (
        <Alert
          intent="info"
          title="Oh no! There is no activity for this period yet..."
          message="Stay tuned! Your activity feed will soon be brimming with endorsements from your colleagues. Every slice of appreciation you send and receive will be showcased here, fostering a culture of gratitude and recognition within your team."
        />
      )}

      {isSuccess && (
        <div className={styles.container}>
          <Table
            arial-label="Activity"
            size="small"
            className={styles.table}
            data-testid="activity-table"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.key} data-testid="activity-table-row">
                  <TableCell className={styles.tableColumnIcon}>
                    {row.icon}
                  </TableCell>
                  <TableCell>{row.message}</TableCell>
                  <TableCell className={styles.tableColumnDate}>
                    <Tooltip
                      withArrow
                      content={formatDateTime(
                        row.date,
                        userSettings.dateFormat,
                        userSettings.timeFormat
                      )}
                      relationship="label"
                    >
                      <span>{formatDistanceToNow(row.date)} ago</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {(pagination?.total && pagination.total > pagination.limit) ===
            true && (
            <Pagination
              selectedPage={selectedPage}
              pagination={pagination}
              onPageChange={onPageChange}
            />
          )}
        </div>
      )}
    </div>
  );
};
