import { DateTime } from "luxon";
import { TeamsContext, TeamsContextType } from "../context/TeamsFxContext";
import {
  ConfigurationDateFormat,
  ConfigurationTimeFormat,
} from "../tabs/Settings/components/Config/constants";

export const capitalize = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const getTeamsContext = (teams: TeamsContext): TeamsContextType => {
  if (teams?.channel) {
    return TeamsContextType.Channel;
  } else if (teams?.meeting) {
    return TeamsContextType.Meeting;
  } else if (teams?.chat && teams.chat?.id.includes("@thread.v2")) {
    // bug-1: dirty hack to detect group chat and exclude the 1:1 chat with the bot itself
    return TeamsContextType.Chat;
  }

  return TeamsContextType.Personal;
};

export const getTeamsContextChatId = (teams: TeamsContext): string | null => {
  const context = getTeamsContext(teams);

  switch (context) {
    case TeamsContextType.Channel:
      return teams.channel?.id ?? null;
    case TeamsContextType.Chat:
      return teams.chat?.id ?? null;
    case TeamsContextType.Meeting:
      return teams.meeting?.id ?? null;
    default:
      return null;
  }
};

export const parseDate = (date: string | Date): Date => {
  if (date instanceof Date) {
    return date;
  }

  return new Date(date);
};

export const formatDate = (
  input: string | Date,
  format: string = ConfigurationDateFormat.Default
): string => {
  const date = parseDate(input);

  if (format === ConfigurationDateFormat.Default) {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT);
  }

  return DateTime.fromJSDate(date).toFormat(format);
};

export const formatTime = (
  input: string | Date,
  format: string = ConfigurationTimeFormat.Default
): string => {
  const date = parseDate(input);

  if (format === ConfigurationTimeFormat.Default) {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_SIMPLE);
    // .replace(/\u202F/g, " "); // Note: Dirty hack to replace non-breaking space
  }

  return DateTime.fromJSDate(date).toFormat(format);
  // .replace(/\u202F/g, " "); // Note: Dirty hack to replace non-breaking space
};

export const formatDateTime = (
  date: string | Date,
  dateFormat: string = ConfigurationDateFormat.Default,
  timeFormat: string = ConfigurationTimeFormat.Default
): string => {
  return `${formatDate(date, dateFormat)} ${formatTime(date, timeFormat)}`;
};
